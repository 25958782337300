<!--
 * @Description: 兼职信息详情
 * @Author: huacong
 * @Date: 2021-05-08 11:03:59
 * @LastEditTime: 2021-07-08 10:15:44
 * @LastEditors: huacong
-->
<template>
  <div class="">
    <!-- contentBox -->
    <div class="infoBoxBg">
      <el-row>
        <el-col :span="16" class="jobInfoShow">
          <p>
            <span> {{ jobInfo.j_certificate }}-{{ jobInfo.j_major }} </span>
            <span class="font-orange">
              {{ jobInfo.j_min_price }}万-{{ jobInfo.j_max_price }}万/1年
            </span>
            <span class="worktypeBtn">
              <i class="el-icon-time"></i>
              兼职
            </span>
          </p>
          <p>
            <span>{{ jobInfo.j_area_province }}-{{ jobInfo.j_area_city }}</span>
            <span>{{ jobInfo.j_register }}经验</span>
            <span>{{ jobInfo.j_purpose }}</span>
            <span>{{ jobInfo.j_social_need }}</span>
          </p>
          <p>
            <span>{{ jobInfo.j_sign_method }}</span>
            <span>{{ jobInfo.j_pay_method }}</span>
            <span> 发布于 {{ jobInfo.publish_time }} </span>
          </p>
        </el-col>
        <el-col :span="8" class="jobInfoOperation">
          <!-- <p class="text-right">
            <el-button
              type="primary"
              class="radius-25"
              v-prevent-repeat-click
              @click="deliver"
            >
              <i class="el-icon-position"></i>
              投递简历
            </el-button>
          </p>
          <p class="text-right">
            <span class="span-btn small-btn btn-font" @click="getPhone">
              <span v-show="!phoneShow">
                <i class="el-icon-view"></i>查看电话</span
              >
              <span v-show="phoneShow">
                <i class="el-icon-mobile-phone"></i>
                {{ publisherPhone }}</span
              >
            </span>
          </p> -->
        </el-col>
      </el-row>
    </div>
    <el-row :gutter="16">
      <el-col :span="24">
        <!-- 信息展示 -->
        <div class="left-infoBox">
          <!-- 发布公司-->
          <div class="comBox">
            <el-avatar :size="60" style="background:#fff;">
              <el-image :src="userInfo.u_portrait_url">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline error-img-60"></i>
                </div>
              </el-image>
            </el-avatar>
            <div
              class="comInfoBox"
              style="display:inline-block;width:calc(100% - 60px)"
            >
              <p class="infoShow">
                <span>{{ userInfo.u_name }}</span>
                <span><img :src="vipIcon" alt=""/></span>
                <span class="font-gary">
                  专业能力：
                  <span class="font-red">
                    {{ userInfo.professionalism }}
                  </span>
                </span>
                <span class="font-gary">
                  在网时间：
                  <span class="font-blue">
                    {{ userInfo.u_regist_time }}个月
                  </span>
                </span>
                <span class="operation">
                  <!-- <span @click="star(jobInfo.id)">
                    <i :class="starIcon">
                      <span style="font-size:14px">{{ starText }}</span>
                    </i>
                  </span>
                  <span @click="report(jobInfo.id)">
                    <i class="el-icon-warning-outline"></i>举报</span
                  >
                  <span> <i class="el-icon-share"></i>分享</span> -->
                </span>
              </p>
              <p>
                <span>{{ comName }}</span>
                <span style="float: right;">
                  发布于 {{ jobInfo.publish_time }}
                </span>
              </p>
            </div>
            <!-- <img src="" alt=""> -->
          </div>
          <!-- 职位描述 -->
          <div>
            <p class="line-title"><span>I</span>职位描述</p>
            <p class="workInfoBox font-gary">{{ jobInfo.j_job_agree }}</p>
          </div>
          <!-- 工作地点 -->
          <div>
            <p class="line-title"><span>I</span>工作地点</p>
            <p class="workInfoBox font-gary">
              {{ jobInfo.j_area_province }}-{{ jobInfo.j_area_city }}
            </p>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  name: "partTimeInfodialog",
  data() {
    return {
      vipIcon: require("@/assets/icon/vip.png"),
      jobInfo: {},
      userInfo: {},
      comName: "",
      starIcon: "el-icon-star-off",
      starText: "收藏",
      starWorkId: "",
      phoneShow: false,
      publisherPhone: "",
      reportShow: false,
      reportInfo: {},
    };
  },
  computed: {
    lander() {
      let k = localStorage.getItem("userInfo");
      if (k == null) {
        return {};
      }
      return JSON.parse(localStorage.getItem("userInfo"));
    },
  },
  created() {
    console.log(this.$parent.$parent.partShow);
    if (this.$parent.$parent.partShow) {
      this.getInfo();
    }
  },
  methods: {
    errorHandler() {
      return true;
    },
    getInfo() {
      let workId = this.$parent.$parent.partID;
      this.$http.post("index/parjob/jobdetail", { id: workId }).then((res) => {
        if (res.data.resultCode == 1) {
          this.jobInfo = res.data.resultData.job;
          this.userInfo = res.data.resultData.user;
          this.comName = res.data.resultData.company[0].com_external_name;
        }
      });
    },
    // 收藏职位
    star(id) {
      if (this.starText == "收藏") {
        this.$http
          .post("talent/index/collection", { type: 1, position_id: id })
          .then((res) => {
            if (res.data.resultCode == 1) {
              console.log(res.data.resultData);
              this.starIcon = "el-icon-star-on font-blue";
              this.starText = "取消收藏";
              this.$message.success("收藏职位成功！");
            }
          });
      } else {
        this.$http
          .post("talent/index/unsetpostion", { id: this.starWorkId })
          .then((res) => {
            if (res.data.resultCode == 1) {
              console.log(res.data.resultData);
              this.starIcon = "el-icon-star-off";
              this.starText = "收藏";
              this.$message.success("取消收藏职位成功！");
            }
          });
      }
    },
    // 已收藏的职位中是否有该职位
    findStar() {
      this.$http
        .post("talent/index/getcollection", { page: 1, limit: 500, type: 1 })
        .then((res) => {
          if (res.data.resultCode == 1) {
            let workId = this.$route.query.id;
            let starWork = res.data.resultData.list;
            starWork.forEach((element) => {
              if (element.position_id == workId) {
                this.starIcon = "el-icon-star-on font-blue";
                this.starText = "取消收藏";
                this.starWorkId = element.id;
              }
            });
          }
        });
    },
    // 兼职投递简历
    deliver() {
      // 判断登录者 身份
      if (this.lander.u_identity == undefined) {
        this.$message.warning("您还没有登录，2秒后跳转登录页面！");
        setTimeout(() => {
          this.$router.push("/Login");
        }, 2000);
        return;
      }
      if (this.lander.u_identity != 1) {
        this.$message.warning("只有人才才能投递简历！");
        return;
      }
      // 投递信息
      let info = {
        publish_uid: this.jobInfo.j_uid,
        com_type: this.jobInfo.j_type,
        com_id: this.jobInfo.j_company_id,
        position_id: this.jobInfo.id,
        position_name: this.jobInfo.j_certificate,
        position_type: 1,
      };
      this.$http.post("talent/index/delivery", info).then((res) => {
        if (res.data.resultCode == 1) {
          this.$notify({
            type: "success",
            title: "投递成功",
            message: "您已投递成功，请耐心等待招聘人员与您联系！",
          });
        }
      });
    },
    getPhone() {
      if (JSON.stringify(this.lander) === "{}") {
        this.$message.warning("登录后才能查看，现在跳转登录！");
        setTimeout(() => {
          this.$route.push("/Login");
        }, 1600);
      } else {
        this.$http
          .post("index/index/findphone", { uid: this.jobInfo.j_uid })
          .then((res) => {
            if (res.data.resultCode == 1) {
              this.phoneShow = true;
              this.publisherPhone = res.data.resultData.u_phone;
            }
          });
      }
    },
    report(id) {
      this.reportShow = true;
      this.reportInfo.job_id = id;
      this.reportInfo.job_type = "兼职";
    },
    postReport() {
      this.$http
        .post("index/resumeinfo/accusation", this.reportInfo)
        .then((res) => {
          if (res.data.resultCode == 1) {
            this.$message.success("感谢您的反馈，我们马上处理您的举报信息！");
            this.reportShow = false;
          }
        });
    },
  },
  mounted() {
    // this.getInfo();
    // this.findStar();
  },
};
</script>
<style scoped>
.contentBox {
  padding-top: 0;
}
.infoBoxBg {
  height: 200px;
  background: url(../../assets/img/infoBoxBg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}
.comInfoBox {
  display: inline-block;
  box-sizing: border-box;
  height: 60px;
  position: relative;
  top: -11px;
  padding-left: 12px;
}
.comInfoBox p:first-child {
  padding: 10px 0 5px;
}
.line-title {
  font-size: 17px;
  font-weight: bold;
}
.line-title span {
  color: #2674fc;
  padding-right: 12px;
}
.workInfoBox {
  padding-bottom: 20px;
  padding-top: 6px;
  text-indent: 16px;
  line-height: 1.7;
}
.left-infoBox {
  background-color: #fff;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0px 20px;
}
.comBox {
  padding-top: 20px;
  padding-bottom: 10px;
}
.comInfoBox .infoShow > span:not(:last-child) {
  padding-right: 12px;
}
.comInfoBox .operation {
  float: right;
}
.comInfoBox .operation > span {
  margin-left: 16px;
}
.comInfoBox .operation > span i {
  font-size: 16px;
}
.operation > span:hover {
  cursor: pointer;
  color: #2573f1;
  box-shadow: 0px 1px 3px #ddd;
}
.jobInfoShow,
.jobInfoOperation {
  padding: 44px 20px;
}
.jobInfoShow p {
  color: #fff;
  padding-bottom: 15px;
}
.jobInfoShow p:first-child {
  font-size: 22px;
  font-weight: bold;
}
.jobInfoShow p:first-child span:not(:last-child) {
  padding-right: 24px;
}
.worktypeBtn {
  background-color: #f7b500;
  border-radius: 4px;
  padding: 6px 10px;
  font-size: 14px;
  font-weight: 200;
}
.jobInfoShow p:nth-child(2) span:not(:first-child),
.jobInfoShow p:nth-child(3) span:not(:first-child) {
  padding-left: 10px;
}
.jobInfoShow p:nth-child(2) span:not(:last-child),
.jobInfoShow p:nth-child(3) span:not(:last-child) {
  padding-right: 10px;
  border-right: 1px solid #fff;
}
.el-icon-position {
  transform: rotateY(180deg);
}
</style>
