<!--
 * @Description: 
 * @Author: huacong
 * @Date: 2021-04-19 10:24:50
 * @LastEditTime: 2021-07-08 13:13:11
 * @LastEditors: huacong
-->
<template>
  <!-- 发布成功 -->
  <div class="contentBox">
    <stepThree></stepThree>
    <div style="height: 50px"></div>
    <section>
      <div class="text-center">
        <img :src="centerImg" alt="" />
      </div>
      <div class="text-center" style="padding: 20px">
        <el-button
          type="primary"
          class="radius-25"
          style="width: 160px"
          v-prevent-repeat-click
          @click="yulanPost"
        >
          预览该信息
        </el-button>
        <el-button
          type="primary"
          class="radius-25"
          style="width: 160px"
          v-prevent-repeat-click
          @click="againPost"
        >
          再发一条
        </el-button>
        <el-button
          type="primary"
          class="radius-25"
          style="width: 160px"
          v-prevent-repeat-click
          @click="goRecruitInfo"
        >
          管理我的发布
        </el-button>
        <el-button
          type="primary"
          class="radius-25"
          style="width: 160px"
          v-prevent-repeat-click
          @click="goIndex"
        >
          进入猎头中心
        </el-button>
      </div>
    </section>
    <!-- 兼职信息 -->
    <el-dialog
      title="预览"
      :visible.sync="partShow"
      :close-on-click-modal="false"
      width="70%"
      @close="partShow = false"
    >
      <partTimeInfodialog></partTimeInfodialog>
    </el-dialog>
    <!-- 全职信息 -->
    <el-dialog
      title="预览"
      :visible.sync="fullShow"
      :close-on-click-modal="false"
      width="70%"
    >
      <fullTimeInfodialog></fullTimeInfodialog>
    </el-dialog>
    <!-- 求职信息 -->
    <el-dialog
      title="预览"
      :visible.sync="resumeShow"
      :close-on-click-modal="false"
      @close="resumeShow = false"
      width="70%"
    >
      <recommeInfodialog></recommeInfodialog>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import stepThree from "@/components/stepThree/stepThree";
import partTimeInfodialog from "@/view/postInfo/partTimeInfodialog";
import fullTimeInfodialog from "@/view/postInfo/fullTimeInfodialog";
import recommeInfodialog from "@/view/postInfo/recommeInfodialog";
export default {
  components: {
    stepThree,
    partTimeInfodialog,
    fullTimeInfodialog,
    recommeInfodialog,
  },
  data() {
    return {
      centerImg: require("@/assets/img/post04.png"),
      partID: "",
      partShow: false,
      fullID: "",
      fullShow: false,
      resumeid: "",
      resumeuid: "",
      resumeShow: false,
    };
  },
  computed: {
    ...mapState(["userInfo", "comRealeInfo", "comStaffInfo", "postReturnInfo"]),
  },
  methods: {
    // 预览发布
    yulanPost() {
      if (this.postReturnInfo.flag == 1) {
        // 职位
        if (this.postReturnInfo.type == 1) {
          // 兼职
          this.partShow = true;
          this.partID = this.postReturnInfo.push_id;
          // this.$router.push({
          //   name: "partTimeInfo",
          //   query: { id: this.postReturnInfo.push_id },
          // });
        } else if (this.postReturnInfo.type == 2) {
          // 全职
          this.fullShow = true;
          this.fullID = this.postReturnInfo.push_id;
          // this.$router.push({
          //   name: "/Home/fullTimeInfo",
          //   query: { id: this.postReturnInfo.push_id },
          // });
        }
      } else if (this.postReturnInfo.flag == 2) {
        // 求职
        this.resumeShow = true;
        this.resumeid = this.postReturnInfo.push_id;
        this.resumeuid = this.userInfo.uid;
        // this.$router.push({
        //   name: "recommeInfo",
        //   query: { id: this.postReturnInfo.push_id, uid: this.userInfo.uid },
        // });
      }
    },
    // 再发布一条
    againPost() {
      this.$router.go(-1);
    },
    // 跳转管理发布页
    goRecruitInfo() {
      this.$router.push("recruitInfo");
    },
    // 前往猎头页
    goIndex() {
      this.$router.push("comHome");
    },
  },
  mounted() {},
};
</script>
