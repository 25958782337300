<!--
 * @Description: 猎头荐才信息/企业求职信息
 * @Author: huacong
 * @Date: 2021-05-08 11:05:43
 * @LastEditTime: 2021-07-12 16:20:29
 * @LastEditors: huacong
-->
<template>
  <div class="">
    <div class="infoBoxBg">
      <el-row>
        <el-col :span="16" class="jobInfoShow">
          <p>
            <span> {{ jobInfo.certificate }}-{{ jobInfo.major }} </span>
            <span class="font-orange"> {{ jobInfo.zs_price }}万 </span>
            <span class="worktypeBtn">
              <i class="el-icon-time"></i>
              求职
            </span>
          </p>
          <p>
            <span>{{ jobInfo.region_province }}-{{ jobInfo.region_city }}</span>
            <span>{{ jobInfo.register }}</span>
            <span>{{
              jobInfo.social == "不限"
                ? "社保" + jobInfo.social
                : jobInfo.social
            }}</span>
            <span> 发布于 {{ jobInfo.create_time }} </span>
          </p>
          <p>
            <!-- <span>{{ jobInfo.register }}</span>
              <span>{{ jobInfo.j_pay_method }}</span>
              <span> 发布于 {{ jobInfo.create_time }} </span> -->
          </p>
        </el-col>
        <el-col :span="8" class="jobInfoOperation">
          <!-- <p class="text-right">
            <el-button type="primary" class="radius-25">
              <i class="el-icon-position"></i>
              立即沟通
            </el-button>
          </p>
          <p class="text-right operation">
            <span v-show="!phoneShow" @click="getPhone">
              <i class="el-icon-view"></i>查看电话</span
            >
            <span v-show="phoneShow">
              <i class="el-icon-mobile-phone"></i> {{ publisherPhone }}</span
            >
            <span> <i class="el-icon-share"></i>分享</span>
          </p> -->
        </el-col>
      </el-row>
    </div>
    <el-row :gutter="16">
      <el-col :span="24">
        <!-- 信息展示 -->
        <div class="left-infoBox">
          <!-- 发布公司-->
          <div class="comBox">
            <el-avatar :size="60" style="background:#fff;">
              <el-image :src="publisherInfo.u_portrait_rul">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline error-img-60"></i>
                </div>
              </el-image>
            </el-avatar>
            <div
              class="comInfoBox"
              style="display:inline-block;width:calc(100% - 60px)"
            >
              <p class="infoShow">
                <span>{{ publisherInfo.u_name }}</span>
                <span><img :src="vipIcon" alt=""/></span>
                <span class="font-gary">
                  专业能力：
                  <span class="font-red">
                    {{ publisherInfo.professionalism }}
                  </span>
                </span>
                <span class="font-gary">
                  回复率：
                  <span class="font-blue">
                    {{ publisherInfo.resume_rate }}%
                  </span>
                </span>
                <span class="font-gary">
                  在网时间：
                  <span class="font-blue">
                    {{ publisherInfo.u_regist_time }}个月
                  </span>
                </span>
              </p>
              <p>
                <span>{{ comName }}</span>
              </p>
            </div>
            <!-- <img src="" alt=""> -->
          </div>
          <!-- 职位描述 -->
          <div style="padding-bottom:20px">
            <p class="line-title"><span>I</span>职位描述</p>
            <p class="workInfoBox font-gary">
              <el-row>
                <el-col :span="12">
                  <span class="font-gary">期望职位/类别:</span>
                  <span>
                    {{ jobInfo.expected_type }}/{{ jobInfo.expected_major }}
                  </span>
                </el-col>
                <el-col :span="12">
                  <span class="font-gary">到岗时间：</span>
                  <span>{{ jobInfo.arrival_time }}</span>
                </el-col>
              </el-row>
            </p>
            <p class="workInfoBox font-gary">
              <el-row>
                <el-col :span="12">
                  <span class="font-gary">证书情况:</span>
                  <span>
                    {{ jobInfo.now_status }}
                  </span>
                </el-col>
                <el-col :span="12">
                  <span class="font-gary">注册情况</span>
                  <span>{{ jobInfo.register }}</span>
                </el-col>
              </el-row>
            </p>
          </div>
          <!-- 工作地点 -->
          <!-- <div>
              <p class="line-title"><span>I</span>工作地点</p>
              <p class="workInfoBox font-gary">
                {{ jobInfo.j_area_province }}-{{ jobInfo.j_area_city }}
              </p>
            </div> -->
        </div>
        <!-- 相关职位 -->
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  name: "recommeInfodialog",
  data() {
    return {
      vipIcon: require("@/assets/icon/vip.png"),
      jobInfo: {},
      publisherInfo: {},
      comName: "",
      phoneShow: false,
      publisherPhone: "",
    };
  },
  computed: {
    userInfo() {
      let k = localStorage.getItem("userInfo");
      console.log(k);
      if (k == null) {
        return {};
      }
      return JSON.parse(localStorage.getItem("userInfo"));
    },
  },
  created() {
    if (this.$parent.$parent.resumeShow) {
      this.getInfo();
    }
  },
  methods: {
    errorHandler() {
      return true;
    },
    getInfo() {
      let id = this.$parent.$parent.resumeid;
      let uid = this.$parent.$parent.resumeuid;
      this.$http
        .post("index/resumeinfo/pardetail", { id: id, uid: uid })
        .then((res) => {
          if (res.data.resultCode == 1) {
            this.jobInfo = res.data.resultData.cer;
            this.publisherInfo = res.data.resultData.user_info;
            this.comName = res.data.resultData.com_info.com_external_name;
          }
        });
    },
    getPhone() {
      if (JSON.stringify(this.userInfo) === "{}") {
        this.$message.warning("登录后才能查看，现在跳转登录！");
        setTimeout(() => {
          this.$route.push("/Login");
        }, 1600);
      } else {
        this.$http
          .post("index/index/findphone", { uid: this.publisherInfo.uid })
          .then((res) => {
            if (res.data.resultCode == 1) {
              this.phoneShow = true;
              this.publisherPhone = res.data.resultData.u_phone;
            }
          });
      }
    },
  },
  mounted() {
    // this.getInfo();
  },
};
</script>
<style scoped>
.contentBox {
  padding-top: 0;
}
.infoBoxBg {
  height: 200px;
  background: url(../../assets/img/infoBoxBg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}
.comInfoBox {
  display: inline-block;
  box-sizing: border-box;
  height: 60px;
  position: relative;
  top: -11px;
  padding-left: 12px;
}
.comInfoBox p:first-child {
  padding: 10px 0 5px;
}
.line-title {
  font-size: 17px;
  font-weight: bold;
}
.line-title span {
  color: #2674fc;
  padding-right: 12px;
}
.workInfoBox {
  padding-bottom: 0px;
  padding-top: 10px;
  text-indent: 16px;
  line-height: 1.7;
}
.left-infoBox {
  background-color: #fff;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0px 20px;
}
.comBox {
  padding-top: 20px;
  padding-bottom: 10px;
}
.comInfoBox .infoShow > span:not(:last-child) {
  padding-right: 12px;
}
.jobInfoOperation .operation {
  padding-top: 14px;
  color: #fff;
}
.jobInfoOperation .operation > span {
  margin-left: 16px;
}
/* .comInfoBox .operation > span i {
  font-size: 16px;
} */
.operation > span:hover {
  cursor: pointer;
  color: #1c82ff;
  /* box-shadow: 0px 1px 3px #ddd; */
}
.jobInfoShow,
.jobInfoOperation {
  padding: 44px 20px;
}
.jobInfoShow p {
  color: #fff;
  padding-bottom: 15px;
}
.jobInfoShow p:first-child {
  font-size: 22px;
  font-weight: bold;
}
.jobInfoShow p:first-child span:not(:last-child) {
  padding-right: 24px;
}
.worktypeBtn {
  background-color: #f7b500;
  border-radius: 4px;
  padding: 6px 10px;
  font-size: 14px;
  font-weight: 200;
}
.jobInfoShow p:nth-child(2) span:not(:first-child),
.jobInfoShow p:nth-child(3) span:not(:first-child) {
  padding-left: 10px;
}
.jobInfoShow p:nth-child(2) span:not(:last-child),
.jobInfoShow p:nth-child(3) span:not(:last-child) {
  padding-right: 10px;
  border-right: 1px solid #fff;
}
</style>
